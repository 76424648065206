import React from 'react';

import PageHeader from '../shared/PageHeader';
import HandlePlay from '../shared/PlayAction';

import '../../scss/pages/_livecasino.scss';

const LiveCasino = () => {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Live casino', url: '' },
    ];

    return (
        <div>
            <div className="site_body">
                <PageHeader breadcrumb={breadcrumb} />

                <div className="main-content" id="maincontent">
                </div>
            </div>
        </div>
    );
};

export default LiveCasino;
